<script>
export default {
  name: 'NoAuth',
  render () {
    return (
      <div>暂无权限!!<br/><br/>如需登录CRM系统请联系企业服务部门开通!!</div>
    )
  }
}
</script>
<style lang='less' scoped>
div {
  padding: 150px 20px;
  font-size: 28px;
  color: #8a98ac;
  text-align: center;
}
</style>