<script>
export default {
  name: '404',
  data() {
    return {
      
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  render() {
    return (
      <div>404</div>
    )
  }
}
</script>
<style lang='less' scoped>
  div{
    padding: 20px;
  }
</style>