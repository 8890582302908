<script>
import { getBusiness, createCustomerApi, updateCustomerApi, getAreaList, getCustomerDetail } from '@/api/custom'
import {
  LEVEL_MAP,
  STATUSTINY_MAP,
  SOURCE_MAP,
  STAGE_MAP,
  // INDUSTRY_MAP,
  INDUSTRY_MAP2,
  GENDER_MAP
} from '@/constant/index'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  data () {
    return {
      renderTree: [
        {
          title: '基础信息',
          groupKey: 'base',
          list: [
            {
              tag: 'van-field',
              props: {
                name: 'name',
                label: '客户名称',
                rules: [{ required: true, message: '请填写客户名称' }]
              },
              attrs: {
                placeholder: '请输入信息'
              }
            },
            // {
            //   tag: 'van-field',
            //   props: {
            //     name: 'phone',
            //     label: '电话',
            //     rules: [{ required: true, message: '请填写电话' }]
            //   },
            //   attrs: {
            //     placeholder: '请输入信息'
            //   }
            // },
            {
              tag: 'van-field',
              isEnum: true,
              props: {
                readonly: true,
                clickable: true,
                name: 'business_id',
                label: '业务线',
                rules: [{ required: true, message: '请输入信息', trigger: 'onChange' }]
              },
              attrs: {
                placeholder: '请点击选择'
              },
              on: {
                click: () => this.handlePickerClick('base', 'business_id')
              }
            },
            {
              tag: 'van-field',
              isEnum: true,
              props: {
                readonly: true,
                clickable: true,
                name: 'level',
                label: '客户级别',
                rules: [{ required: true, message: '请选择客户级别', trigger: 'onChange' }]
              },
              attrs: {
                placeholder: '请点击选择'
              },
              on: {
                click: () => this.handlePickerClick('base', 'level')
              }
            },
            {
              tag: 'van-field',
              isEnum: true,
              props: {
                readonly: true,
                clickable: true,
                name: 'source',
                label: '客户来源',
                rules: [{ required: true, message: '请选择客户来源', trigger: 'onChange' }]
              },
              attrs: {
                placeholder: '请点击选择'
              },
              on: {
                click: () => this.handlePickerClick('base', 'source')
              }
            },
            {
              tag: 'van-field',
              isEnum: true,
              props: {
                readonly: true,
                clickable: true,
                name: 'stage',
                label: '客户阶段',
                rules: [{ required: true, message: '请选择客户阶段', trigger: 'onChange' }]
              },
              attrs: {
                placeholder: '请点击选择'
              },
              on: {
                click: () => this.handlePickerClick('base', 'stage')
              }
            },
            // {
            //   tag: 'van-field',
            //   isEnum: true,
            //   props: {
            //     readonly: true,
            //     clickable: true,
            //     name: 'industry',
            //     label: '行业',
            //     rules: [{ required: true, message: '请选择行业', trigger: 'onChange' }]
            //   },
            //   attrs: {
            //     placeholder: '请点击选择'
            //   },
            //   on: {
            //     click: () => this.handlePickerClick('base', 'industry')
            //   }
            // },
            {
              tag: 'van-field',
              isCascader: true,
              props: {
                readonly: true,
                clickable: true,
                name: 'industry',
                label: '行业',
                rules: [{ required: true, message: '请选择行业', trigger: 'onChange' }]
              },
              attrs: {
                placeholder: '请点击选择'
              },
              on: {
                click: () => this.handlePickerClick('base', 'industry')
              }
            },
            {
              tag: 'van-field',
              isCascader: true,
              props: {
                readonly: true,
                clickable: true,
                name: 'city',
                label: '客户所在地'
              },
              attrs: {
                placeholder: '请点击选择'
              },
              on: {
                click: () => this.handlePickerClick('base', 'city')
              }
            },
            {
              tag: 'van-field',
              props: {
                name: 'need',
                label: '客户需求',
                rules: [{ required: true, message: '请填写客户需求' }]
              },
              attrs: {
                placeholder: '请输入信息'
              }
            },
            {
              tag: 'van-field',
              props: {
                name: 'remark',
                label: '备注'
              },
              attrs: {
                placeholder: '请输入信息'
              }
            }
          ]
        },
        {
          title: '联系人信息',
          groupKey: 'contact',
          list: [
            {
              tag: 'van-field',
              props: {
                name: 'name',
                label: '姓名',
                rules: [{ required: true, message: '请填写联系人姓名' }]
              },
              attrs: {
                placeholder: '请输入信息',
                mark: 'name'
              }
            },
            {
              tag: 'van-field',
              props: {
                name: 'phone',
                label: '电话',
                rules: [{ required: true, message: '请填写联系人电话' }]
              },
              attrs: {
                placeholder: '请输入信息',
                mark: 'phone'
              }
            },
            {
              tag: 'van-field',
              isEnum: true,
              props: {
                readonly: true,
                clickable: true,
                name: 'gender',
                label: '性别',
                rules: [{ required: true, message: '请选择联系人性别' }]
              },
              attrs: {
                placeholder: '请点击选择',
                mark: 'gender'
              },
              on: {
                click: () => this.handlePickerClick('contact', 'gender')
              }
            },
            {
              tag: 'van-field',
              props: {
                name: 'position',
                label: '职务',
                rules: [{ required: true, message: '请填写联系人职务' }]
              },
              attrs: {
                placeholder: '请输入信息',
                mark: 'position'
              }
            },
          ],
        }
      ],
      options: {
        level: LEVEL_MAP,
        statustiny: STATUSTINY_MAP,
        source: SOURCE_MAP,
        stage: [],
        // industry: INDUSTRY_MAP,
        gender: GENDER_MAP,
        city: [],
        business_id: [],
        industry: INDUSTRY_MAP2,
      },
      // 当前picker对应的字段名
      selectPicker: {},
      value: '',
      customForm: {
        base: {},
        // contact: {},
        contact: [
          {
            name: "",
            phone: "",
            gender: "",
            position: ""
          }
        ]
      },
      showPicker: false,
      showCityPicker: false,
      loading: {
        submit: false,
        global: true
      },
      customerId: '',
      contactCount: 0,
      delContactList: []
    }
  },
  computed: {
    ...mapState({
      personRole: state => state.personRoleType
    }),
    isUpdate () {
      return this.$route.query?.customerId || false
    }
  },
  watch: {
    personRole:{
      immediate: true,
      handler (nv) {
        this.options.stage = STAGE_MAP[nv]
        // this.options.stage = STAGE_MAP['BUSINESS']
      }
    }
  },
  async mounted () {
    // TODO：待优化
    await this.getAreaList()
    await this.getBusiness()
    if (this.isUpdate) {
      this.customerId = this.$route.query.customerId
      await this.getCustomerDetail()
    }
    // this.setInitial()
    this.loading.global = false
  },
  methods: {
    setInitial () {
      if (this.personRole === 'SDK') {
        this.customForm.base = {
          business_id: '数据发展',
          source: '其他'
        }
      }
    },
    // TODO: 根据角色获取业务线
    async getBusiness () {
      const { data } = await getBusiness({ type: this.personRole === 'SDK' ? 2 : 1 })
      this.options.business_id = Object.values(data).map(v => ({
        label: v.name,
        value: v.id
      }))
    },
    async getAreaList () {
      const { data } = await getAreaList()
      this.options.city = data
    },
    async getCustomerDetail () {
      const enumToLabel = (groupKey, d) => {
        this.renderTree.find(group => group.groupKey === groupKey).list.forEach(item => {
          const key = item.props.name
          let value = d[key]
          if (item.isEnum || item.isCascader) {
            const options = item.isEnum ? this.options[key] : Array.prototype.concat.apply([], this.options[key].map(v => {
              v.children.forEach(child => { child.parent = v.name })
              return v.children
            }))
            const matchKey = item.isEnum ? 'value' : 'id'
            const matchLabel = item.isEnum ? 'label' : 'name'
            const matchValue = options.find(v => v[matchKey] == value)
            value = item.isEnum ? matchValue?.[matchLabel] || '' : [matchValue?.parent, matchValue?.name].filter(Boolean)
          };
          this.$set(this.customForm[groupKey], key, value)
        })
      }
      const { data } = await getCustomerDetail(this.customerId);
      const { contact } = data;
      enumToLabel('contact', contact);
      // this.$set(this.customForm['base'], 'industry2', data.industry + '/' + data.industry_sublevel)
      this.customForm['base'].industry = data.industry + '/' + data.industry_sublevel;
      let len = contact.length;
      this.contactCount = len-1;
      if(len > 1) {
        for(let i=1; i<len; i++) {
          this.renderTree[1].list.push({
            tag: 'van-field',
            props: {
              name: 'name-' + i.toString(),
              label: '姓名',
              rules: [{ required: true, message: '请填写联系人姓名' }]
            },
            attrs: {
              placeholder: '请输入信息',
              mark: 'name'
            }
          },
          {
            tag: 'van-field',
            props: {
              name: 'phone-' + i.toString(),
              label: '电话',
              rules: [{ required: true, message: '请填写联系人电话' }]
            },
            attrs: {
              placeholder: '请输入信息',
              mark: 'phone'
            }
          },
          {
            tag: 'van-field',
            isEnum: true,
            props: {
              readonly: true,
              clickable: true,
              name: 'gender-' + i.toString(),
              label: '性别',
              rules: [{ required: true, message: '请选择联系人性别' }]
            },
            attrs: {
              placeholder: '请点击选择',
              mark: 'gender'
            },
            // on: {
            //   click: (e, val) => this.handlePickerClick('contact', 'gender', val)
            // }
          },
          {
            tag: 'van-field',
            props: {
              name: 'position-' + i.toString(),
              label: '职务',
              rules: [{ required: true, message: '请填写联系人职务' }]
            },
            attrs: {
              placeholder: '请输入信息',
              mark: "position"
            }
          })
        };
      }
      contact.forEach((item, index) => {
        if (item.gender == 'm') {
          item.gender = '男'
        } else {
          item.gender = '女'
        }
      });
      this.$set(this.customForm, 'contact', contact);
      enumToLabel('base', data)
      if (data.industry_sublevel != '') {
        this.customForm.base.industry = [data.industry, data.industry_sublevel]
      } else {
        this.customForm.base.industry = [data.industry]
      }
      if (Reflect.has(contact, 'id')) {
        this.customForm.contact.cotact_id = contact.id
      }
      // this.renderTree.find(group => group.groupKey === 'base').list.forEach(item => {
      //   const key = item.props.name
      //   let value = data[key]
      //   if (item.isEnum || item.isCascader) {
      //     const options = item.isEnum ? this.options[key] : Array.prototype.concat.apply([], this.options[key].map(v => {
      //       v.children.forEach(child => { child.parent = v.name })
      //       return v.children
      //     }))
      //     const matchKey = item.isEnum ? 'value' : 'id'
      //     const matchLabel = item.isEnum ? 'label' : 'name'
      //     const matchValue = options.find(v => v[matchKey] == value)
      //     value = item.isEnum ? matchValue?.[matchLabel] || '' : [matchValue?.parent, matchValue?.name].filter(Boolean)
      //   };
      //   this.$set(this.customForm.base, key, value)
      // })
      
    },
    cancelAdd () {
      this.delContactList = []
      this.$router.push('/customer')
    },
    handlePickerClick (formKey, prop, idx=0) {
      this.showPicker = true
      if (prop == 'city') {
        const arr = this.options[prop]

        const loop = (arr) => {
          return arr.map(item => {
            item.text = item.name
            if ('children' in item) {
              loop(item.children)
            }
            return item
          })
        }
        this.columns = loop(arr)
      } else if(prop == 'industry') {
        this.columns = this.options[prop]
      } else {
        this.columns = this.options[prop]?.map(v => v.label) || []
      }
      this.selectPicker = {
        // formKey, prop
        formKey, prop, idx
      }
    },
    onConfirm (value) {
      // const { formKey, prop } = this.selectPicker; //原来
      const { formKey, prop, idx } = this.selectPicker;
      this.value = value;
      this.customForm[formKey][prop] = value;
      //新添加
      if (formKey == "base") {
        if (prop == 'industry') {
          this.customForm[formKey][prop] = value[1].length == 0 ? value[0] : value;
        }
      } else {
        this.customForm[formKey][idx][prop] = value;
      }
      this.showPicker = false;
    },
    submit () {
      const enumToVal = (params) => {
        return Object.entries(params).reduce((prev, curr) => {
          const [key, val] = curr
          if (Object.keys(this.options).includes(key) && key != 'industry') {
            const isCascader = Reflect.has(this.options[key][0], 'children')
            const options = isCascader ? Array.prototype.concat.apply([], this.options[key].map(v => v.children)) : this.options[key]
            const matchKey = !isCascader ? 'value' : 'id'
            const matchLabel = !isCascader ? 'label' : 'name'
            const matchValue = !isCascader ? val : val[1]
            prev[key] = options.find(v => v[matchLabel] == matchValue)?.[matchKey] || null
          } else {
            prev[key] = val
          }
          return prev
        }, {})
      }
      this.loading.submit = true
      const { base } = this.customForm
      // const params = { ...enumToVal(base), contact: enumToVal(contact) }
      // if (this.isUpdate) params.customer_id = this.customerId
      const fetchFn = this.isUpdate ? updateCustomerApi : createCustomerApi
      //修改
      let params = {
        ...enumToVal(base)
      }
      if (this.isUpdate) params.customer_id = this.customerId
      if(Array.isArray(this.customForm.base.industry)) {
        params.industry_sublevel = this.customForm.base.industry[1];
        params.industry = this.customForm.base.industry[0];
      } else {
        params.industry = this.customForm.base.industry;
        params.industry_sublevel = '';
      }
      let contactGroup = this.customForm.contact.map(item => {
        let obj = {};
        if (Reflect.has(item, 'id')) {
          obj.contact_id = item.id;
        }
        obj.name = item.name;
        obj.position = item.position;
        obj.phone = item.phone;
        obj.gender = item.gender=='男'? 'm' : 'f';
        return obj
      });
      if(this.isUpdate) {
        let delArr = [];
        if(this.delContactList.length > 0) {
          delArr = this.delContactList.map(item => {
            let obj = {};
            obj.contact_id = item.id;
            obj.name = item.name;
            obj.position = item.position;
            obj.phone = item.phone;
            obj.gender = item.gender=='男'? 'm' : 'f';
            obj.is_delete = 2;
            return obj
          })
        }
        contactGroup = contactGroup.concat(delArr)
      }
      params.contact = contactGroup
      fetchFn(params).then(({ code, message }) => {
        if (code === 200) {
          this.$notify({
            type: 'success',
            message
          })
          setTimeout(() => {
            this.$router.push('/customer')
          }, 400)
        } else {
          this.$notify({
            type: 'danger',
            message
          })
        }
      }).finally(() => {
        this.loading.submit = false
      })
    },
    addContactGroup() {
      this.contactCount += 1;
      let idx = this.contactCount;
      this.renderTree[1].list.push({
        tag: 'van-field',
        props: {
          name: 'name-' + idx.toString(),
          label: '姓名',
          rules: [{ required: true, message: '请填写联系人姓名' }]
        },
        attrs: {
          placeholder: '请输入信息',
          mark: 'name'
        }
      },
      {
        tag: 'van-field',
        props: {
          name: 'phone-' + idx.toString(),
          label: '电话',
          rules: [{ required: true, message: '请填写联系人电话' }]
        },
        attrs: {
          placeholder: '请输入信息',
          mark: 'phone'
        }
      },
      {
        tag: 'van-field',
        isEnum: true,
        props: {
          readonly: true,
          clickable: true,
          name: 'gender-' + idx.toString(),
          label: '性别',
          rules: [{ required: true, message: '请选择联系人性别' }]
        },
        attrs: {
          placeholder: '请点击选择',
          mark: 'gender'
        },
        // on: {
        //   click: (e, val) => this.handlePickerClick('contact', 'gender', val)
        // }
      },
      {
        tag: 'van-field',
        props: {
          name: 'position-' + idx.toString(),
          label: '职务',
          rules: [{ required: true, message: '请填写联系人职务' }]
        },
        attrs: {
          placeholder: '请输入信息',
          mark: "position"
        }
      });
      this.customForm.contact.push({
        name: '',
        phone: '',
        position: '',
        gender: '',
      })
    },
    delContactGroup(index, e) {
      e.preventDefault();
      let idx = Math.floor(index/4);
      this.renderTree[1].list.splice(index-3, 4);
      let delContact = this.customForm.contact[idx]; 
      if (Reflect.has(delContact, 'id')) {
        this.delContactList.push(delContact)
      }
      this.customForm.contact.splice(idx, 1);
    }
  },
  render (h) {
    return (
      <div class="home">
        <van-nav-bar title={this.isUpdate ? '编辑客户' : '新建客户'} left-text="取消" on-click-left={this.cancelAdd} />
        <van-form style='position: relative' on-submit={this.submit}>
          {
            this.loading.global ? <van-loading type="spinner" color="#1989fa" size="24px" vertical class='global-loading'>加载中...</van-loading> : null
          }
          {
            this.renderTree.map(group => (
              <van-cell-group key={group.title} title={group.title}>
                <template slot="title">
                  <div style="display:flex;justifyContent: space-between;">
                    <span>{group.title}</span>
                    {group.groupKey=='contact' ? <a href="javascript:void(0)" style="color: #029488;" onClick={this.addContactGroup}>添加联系人组</a> : null}
                  </div>
                </template>
                {
                  group.groupKey=='base' ?
                    group.list.map(formItem => {
                      const val = this.customForm[group.groupKey][formItem.props.name]
                      return (<van-field
                        key={formItem.props.name}
                        {...{
                          props: {
                            ...formItem.props,
                            value: Array.isArray(val) ? val.join('/') : val
                          },
                          attrs: formItem.attrs,
                          on: {
                            input: val => {
                              this.$set(this.customForm[group.groupKey], formItem.props.name, val)
                            },
                            ...formItem.on
                          }
                        }}
                      />
                      )
                    }) 
                    : group.list.map((formItem, index) => {
                      let idx = Math.floor(index/4) //获取第n组的联系人索引
                      const val = this.customForm[group.groupKey][idx][formItem.attrs.mark];//gai
                      // const val = this.customForm[group.groupKey][formItem.props.name];
                      return (<div><van-field
                        key={formItem.props.name}
                        {...{
                          props: {
                            ...formItem.props,
                            value: Array.isArray(val) ? val.join('/') : val
                            // value: Array.isArray(val2) ? val2.join('/') : val2
                          },
                          attrs: formItem.attrs,
                          on: {
                            input: val => {
                              // this.$set(this.customForm[group.groupKey][idx], formItem.props.name, val)
                              this.$set(this.customForm[group.groupKey], formItem.props.name, val)
                              this.$set(this.customForm[group.groupKey][idx], formItem.attrs.mark, val)
                            },
                            click: ()=> {
                              if(formItem.attrs.mark == 'gender') {
                                this.handlePickerClick('contact', 'gender', idx)
                              }
                            }
                          }
                        }}
                      />
                      {index > 3 && index % 4 == 3 ? <div class="button-content"><van-button size="small" type="danger" onClick={this.delContactGroup.bind(this, index)}>删除</van-button></div> : index % 4 == 3 ? <p></p> : '' }
                      </div>
                      )
                    })
                }
              </van-cell-group>
            ))
          }
          <van-popup v-model={this.showPicker} position="bottom">
            <van-picker
              visible-item-count="8"
              show-toolbar
              columns={this.columns}
              on-confirm={this.onConfirm}
              on-cancel={() => { this.showPicker = false }}/>
          </van-popup>
          <div style="margin: 16px;">
            <van-button class="pri-btn-block" round block type="info" loading={this.loading.submit}>提交</van-button>
          </div>
        </van-form>
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.global-loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  background: rgba(255, 255, 255, 0.4);
  justify-content: center;
}
.customer-name {
  font-size: 32px;
  font-weight: normal;
}
.customer-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.van-cell__title {
  color: #051833;
}
.button-content {
  display: flex;
  justify-content: flex-end;
  padding: 10px 32px;
}
</style>
