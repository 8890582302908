<template>
  <div class="agent">
    <van-nav-bar title="待办事项"></van-nav-bar>

    <van-pull-refresh v-model="loading.refresh" @refresh="onRefresh">
      <van-list
        v-model="loading.load"
        offset="100"
        :finished="loadFinshed"
        @load="onLoad">
      <div class="w main-list">
        <p class="undo">待领取客户</p>
        <div class="empty" v-if="scheduleList.length === 0">
          <img src="@/assets/empty_1.svg" alt="空">
          <p>暂无更多数据</p>
        </div>
        <div
          class="custom-item customer-list"
          v-for="customer in scheduleList"
          :key="customer.id">
          <div class="customer-info">
            <h3>
              <span class="undo-name">{{customer.name}} </span>
              <van-tag color="#FFEFE7" text-color="#FF6011">{{(customer.level || '')|formattter(enums.level)}}</van-tag>
            </h3>
            <p class="desc">{{customer.phone}}</p>
          </div>
          <div class="track-btn">
            <van-button type="primary" size="small" round @click="getReceiveCustom(customer)">领取</van-button>
          </div>
        </div>
      </div>
      </van-list>
    </van-pull-refresh>
    <nav-bar :active="1" />
  </div>
</template>

<script>
import NavBar from '@/components/navBar.vue'
import { agentSchedule, receiveCustom } from '@/api/custom'
import {
  LEVEL_MAP,
  STAGE_MAP
} from '@/constant/index'

export default {
  name: 'Home',
  components: {
    NavBar
  },
  data () {
    return {
      loading: {
        refresh: false,
        load: false
      },
      enums: {
        level: LEVEL_MAP,
        stage: STAGE_MAP.BUSINESS
      },
      loadFinshed:false,
      isLoading: false,
      scheduleList: [],
      pageNo: 1,
      pageSize: 15,
      total: 0
    }
  },
  mounted () {
    this.getSchedules()
  },
  filters: {
    formattter (val, list) {
      return list.find(v => v.value === val)?.label
    }
  },
  methods: {
    getSchedules() {
      this.loading.load = true
      agentSchedule({
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then((res)=>{
        const {total, list} = res.data
        this.scheduleList = list
        this.total = total
        this.loadFinshed= this.scheduleList.length >= total
      }).finally(()=>{
        this.loading.load = false
      })
    },
    async onRefresh () {
      // this.total = 0
      // await this.getSchedules();
      // this.loading.refresh = false
    },
    onLoad () {
      // this.pageNo += 1
      // this.getSchedules()
    },
    getReceiveCustom(data) {
      receiveCustom({id: data.id})
      .then(res => {
        if(res.code === 200) {
          this.$notify({
            type: 'success',
            message: '领取成功！'
          })
          this.getSchedules()
        }
      })
    }
  }
}
</script>
<style lang="less">
.agent{
  .customer-info{
    h3{
        word-break: break-all;
      }
    word-break: break-all;
  }
  .undo{
    padding: 20px 0;
    font-size: 26px;
    color: #394961;
  }
  .van-tag{
    display: inline-block;
  }
  .track-btn{
    min-width: 160px;
  }
  .empty{
    text-align: center;
    padding-top: 100px;
    img{
      width: 380px;
      height: 380px;
      object-fit: cover;
    }
    p{
      font-size: 28px;
      color: #8A98AC;
      line-height: 36px;
      margin-top: 36px;
    }
  }
}
</style>
