<template>
  <div>
    <van-nav-bar title="客户名称" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon name="friends-o" size="24" />
      </template>
    </van-nav-bar>
    <div class="detailContent">
      <div class="contentTop">
        <van-row type="flex" justify="space-around">
          <van-col span="7" class="boxOne">
            <p>客户阶段：</p>
            <h3>{{ computedStage }}</h3>
          </van-col>
          <van-col span="7" class="boxTwo">
            <p>客户级别：</p>
            <h3>高</h3>
          </van-col>
          <van-col span="7" class="boxThree">
            <p>客户来源：</p>
            <h3>{{computedSource}}</h3>
          </van-col>
        </van-row>
      </div>
      <div class="contentCenter">
        <van-tabs v-model="activeName" @change="changeTabs">
          <van-tab title="客户资料" name="base">
            <van-cell-group title="基础信息">
              <van-cell title="客户名称" :value="customInfo.name" />
              <van-cell title="电话" :value="customInfo.phone" />
              <van-cell title="客户级别" :value="customInfo.level | level_map" />
              <van-cell title="负责人" :value="customInfo.person_aaa_id_name" />
              <van-cell title="客户来源" :value="customInfo.source_name" />
              <van-cell title="业务线" :value="customInfo.business_name" />
              <van-cell title="客户所在地" :value="customInfo.city_name" />
              <!-- <van-cell title="行业" :value="customInfo.industry | industry_map" /> -->
              <van-cell title="行业" :value="industryMap" />
              <van-cell title="项目预估金额" :value="customInfo.estimated_amount" />
              <van-cell title="客户需求" :value="customInfo.need" />
              <van-cell title="备注" :value="customInfo.remark" />
            </van-cell-group>
            <van-cell-group title="联系人信息">
              <!-- <van-cell title="姓名" :value="customInfo.contact.name" />
              <van-cell title="电话" :value="customInfo.contact.phone" />
              <van-cell title="性别" :value="customInfo.contact.gender | gender_map" />
              <van-cell title="职务" :value="customInfo.contact.position" />-->
              <div v-for="(item, index) in customInfo.contact" :key="index" style="border-top:2px solid #eee;">
                <van-cell title="姓名" :value="item.name" />
                <van-cell title="电话" :value="item.phone" />
                <van-cell title="性别" :value="item.gender | gender_map" />
                <van-cell title="职务" :value="item.position" />
              </div>
            </van-cell-group> 
            
            <van-cell-group title="系统信息">
              <van-cell title="创建人" :value="customInfo.create_aaa_name" />
              <van-cell title="创建时间" :value="customInfo.created_at" />
              <van-cell title="最后更新人" :value="customInfo.update_aaa_name" />
              <van-cell title="最后更新时间" :value="customInfo.updated_at" />
            </van-cell-group>
          </van-tab>
          <van-tab title="跟进记录" name="record">
            <h3 @click="showPublic = true">
              <van-icon name="edit" size="20" v-if="!showPublic" /> 发布跟进记录
            </h3>
            <div class="public-content" v-if="showPublic">
              <div class="setFollowList">
                <van-form ref="followRef" @submit="onSubmit">
                  <van-field
                    readonly
                    clickable
                    :value="follow.type | follow_type"
                    name="picker"
                    label="跟进类型"
                    placeholder="请输入信息"
                    :rules="[{ required: true, message: '请选择跟进类型'}]"
                    @click="showPicker = true"
                  />
                  <van-field
                    readonly
                    clickable
                    :value="follow.contact_date"
                    name="calendar"
                    label="跟进时间"
                    placeholder="请输入信息"
                    :rules="[{ required: true, message: '请选择跟进时间'}]"
                    @click="showCalendar1 = true"
                  />
                  <van-field
                    v-model="follow.content"
                    name="跟进内容"
                    label="跟进内容"
                    :rules="[{ required: true, message: '请输入跟进内容'}]"
                    placeholder="请输入信息"
                  />
                  <van-field
                    v-model="follow.plan"
                    name="下一步计划"
                    label="下一步计划"
                    placeholder="请输入信息"
                  />
                  <van-field
                    readonly
                    clickable
                    :value="follow.next_contact_date"
                    name="calendar"
                    label="下次跟进时间"
                    placeholder="请输入信息"
                    @click="showCalendar2 = true"
                  />
                  <div class="footerButTwo">
                    <van-button
                      type="default"
                      size="normal"
                      class="footer-btnS"
                      @click="cancelAddFollow"
                      >取消</van-button
                    >
                    <van-button
                      type="primary"
                      block
                      size="normal"
                      class="footer-btnS fr"
                      native-type="submit"
                      >确认保存</van-button
                    >
                  </div>
                </van-form>
                <van-calendar
                  v-model="showCalendar1"
                  @confirm="onConfirmFollow"
                />
                <van-calendar
                  v-model="showCalendar2"
                  @confirm="onConfirmFollowNext"
                />
                <van-popup v-model="showPicker" position="bottom">
                  <van-picker
                    show-toolbar
                    :columns="followType"
                    @confirm="onConfirmPicker"
                    @cancel="showPicker = false"
                  />
                </van-popup>
              </div>
            </div>
            <h3>跟进记录</h3>
            <div class="followList">
              <div
                class="card"
                v-for="record in followRecordList"
                :key="record.id"
              >
                <div class="list_h">
                  <span class="list_h_L">{{
                    judgeFollowType(record.type)
                  }}</span>
                  <span class="fr color_8A98AC">{{ record.contact_date }}</span>
                </div>
                <p class="list_p">
                  {{ record.content }}
                </p>
                <div class="list_tip">
                  <span>客户来源内容</span>
                  <span class="fr">{{ record.creator }}</span>
                </div>
              </div>
            </div>
          </van-tab>
          <van-tab title="团队成员" name="member">
            <div
              class="memberList"
              v-for="member in memberList"
              :key="member.id"
            >
              <div class="iconStyle fl">
                <img src="../assets/icon_team.svg" />
              </div>
              <div class="memberContent">
                <div>
                  <span class="aliasStyle">{{ member.name }}</span>
                  <span class="memberRole">{{
                    member.role == 1 ? '成员' : '负责人'
                  }}</span>
                </div>
                <div class="postStyle">{{ member.position }}</div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { getCustomerDetail, getFollowMember, getFollowList, addFollow } from '@/api/custom';
import { FOLLOW_TYPES, SOURCE_MAP, STAGE_MAP, GENDER_MAP } from '@/constant'
import { mapState } from 'vuex'
// import { dayjs } from "dayjs";
var dayjs = require('dayjs')

export default {
  data () {
    return {
      activeName: 'base',
      customInfo: {
        contact: {}
      },
      memberList: [],
      followRecordList: [],
      showPublic: false,
      showPicker: false,
      showCalendar1: false,
      showCalendar2: false,
      follow: {},
      followType: FOLLOW_TYPES
    }
  },
  filters:{
    level_map(value) {
      let result = ''
      switch (value)
        {
          case 'A':
            result = '高'
            break
          case "B":
            result = '中'
            break
          case "C":
            result = '低'
            break
        }
      return result
    },
    industry_map(value) {
      let result = ''
      switch (value)
        {
          case 1:
            result = '金融保险'
            break
          case 2:
            result = '地产行业'
            break
          case 3:
            result = '电子商务'
            break
          case 4:
            result = '政府机关'
            break
          case 5:
            result = '教育培训'
            break
          case 6:
            result = '对外贸易'
            break
          case 7:
            result = '医疗'
            break
          case 8:
            result = '酒店旅游'
            break
          // case 9:
          //   result = '零售'
          //   break
          case 10:
            result = '零售'
            break
          case 11:
            result = '交通运输'
            break
          case 12:
            result = '金融保险'
            break
          case 13:
            result = '媒体娱乐'
            break
        }
      return result
    },
    gender_map(value) {
      if(!value) {
        return ''
      }
      return GENDER_MAP.find(gender => gender.value == value)?.label
    },
    follow_type(value) {
      let result = ''
      switch (value)
        {
          case 1:
            result = '电话'
            break
          case 2:
            result = '微信'
            break
          case 3:
            result = '拜访'
            break
          case 99:
            result = '其他'
            break
        }
      return result
    },
  },
  computed: {
    ...mapState({
      personRole: state => state.personRoleType
    }),
    computedStage () {
      // STAGE_MAP
      console.log('---------',this.customInfo)
      const stage = STAGE_MAP?.[this.personRole]?.find(item => item.value == this.customInfo.stage) || [];
      return stage?.label || ''
    },
    computedSource() {
      const source = SOURCE_MAP.find(item => item.value == this.customInfo.source);
      return source?.label || ''
    },
    industryMap() {
      let { industry, industry_sublevel} = this.customInfo
      if (industry_sublevel != '') {
        return industry + '/' + industry_sublevel
      } else {
        return industry
      }
    }
  },
  mounted () {
    this.getCustomerDetail();
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName;
      this.showPublic = true;
      this.getRecordList();
    }
  },
  methods: {
    async getCustomerDetail () {
      const { data } = await getCustomerDetail(this.$route.params.id);
      this.customInfo = data;
      this.customInfo.phone = this.customInfo.contact[0].phone
    },
    changeTabs () {
      if (this.activeName == 'member') {
        return this.getMemberList()
      } else if (this.activeName == 'record') {
        return this.getRecordList()
      }
    },
    async getMemberList () {
      const params = {
        customerId: this.$route.params.id
      }

      const { data } = await getFollowMember(params);
      this.memberList = data.list;
    },
    async getRecordList () {
      const params = {
        customer_id: this.$route.params.id,
        customer_type: 1 // TODO 
      }

      const { data } = await getFollowList(params);
      this.followRecordList = data;
    },
    judgeFollowType (type) {
      const Item = this.followType.find(item => item.id == type)
      return Item?.text || ''
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    cancelAddFollow() {
      this.$refs.followRef.resetValidation();
      this.follow = {}
      this.showPublic = false;
    },
    onSubmit (values) {
      console.log('submit', values);
      const params = {
        customer_id: this.$route.params.id,
        customer_type: 1,
        ...this.follow
      }
      addFollow(params)
        .then(res => {
          if (res.code == 200) {
            this.$notify({
              type: 'success',
              message: '发布跟进成功！'
            })
            this.cancelAddFollow(); // reset表单
            this.getRecordList();
          } else {
            this.$notify({
              type: 'danger',
              message
            })
          }
        })
    },
    onConfirmPicker (value) {
      this.follow.type = value.id;
      this.showPicker = false;
    },
    onConfirmFollow (value) {
      console.log(value);
      this.follow.contact_date = dayjs(value).format('YYYY-MM-DD');
      this.showCalendar1 = false;
      console.log(this.follow)
    },
    onConfirmFollowNext (value) {
      console.log(value);
      this.follow.next_contact_date = dayjs(value).format('YYYY-MM-DD')
      this.showCalendar2 = false
    }
  }
}
</script>
<style lang="less" scoped>
.detailContent {
  width: 100%;
}
.contentTop {
  padding: 20px;
  color: #fff;
  font-size: 26px;
  p{
    font-size: 26px;
  }
  h3 {
    font-size: 34px;
    margin-top: 32px;
    font-weight: normal;
  }
  .boxOne {
    background: #00c0a5;
    padding: 24px;
    border-radius: 10px;
  }
  .boxTwo {
    background: #0d92f3;
    padding: 24px;
    border-radius: 10px;
  }
  .boxThree {
    background: #606c93;
    padding: 24px;
    border-radius: 10px;
  }
}
.contentCenter {
  width: 100%;
  h3 {
    padding-top: 30px;
    padding-left: 32px;
    padding-bottom: 30px;
    font-size: 26px;
    font-weight: 300;
    display: block;
    color: #394961;
  }
}
.centerBox {
  background: #fff;
}
.footerBut {
  padding: 50px 32px;
}
.footerButTwo {
  padding: 25px 50px;
}
.setFollowList {
  width: 100%;
  background: #fff;
}
.followList {
  padding: 0 32px 40px 32px;
}
.van-cell__title {
  color: #8a98ac;
}
.van-cell__value {
  color: #051833;
}
.footer-btnS {
  width: 40%;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.list_h {
  border-left: 10px solid #029488;
  color: #029488;
  font-size: 34px;
  padding-left: 15px;
  line-height: 1;
}
.list_h_L {
  max-width: 50%;
}
.list_h.lr {
  color: #8a98ac;
}
.list_p {
  color: #051833;
  font-size: 34px;
  margin-top: 20px;
  margin-bottom: 20px;
  word-break: break-all;
}
.list_tip {
  color: #8a98ac;
  font-size: 26px;
}
.memberList {
  padding: 32px;
  background: #fff;
  border-bottom: 1px solid #ebeff4;
}
.aliasStyle {
  color: #051833;
  font-size: 32px;
  margin-right: 10px;
}
.memberRole {
  border: 1px solid #029488;
  background: #e3f3f2;
  color: #029488;
  font-size: 22px;
  padding-left: 10px;
  padding-right: 10px;
}
.postStyle {
  color: #8a98ac;
  font-size: 28px;
  margin-top: 10px;
}
.iconStyle {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  margin-right: 30px;
  img {
    width: 100%;
  }
}
.color_8A98AC {
  color: #8a98ac;
}
</style>
